import request from '@/utils/request'

const userApi = {
  instructList: '/api/instruct_total',

}


 // 指令-指令查询
export function reqInstructList(parameter) {
  return request({
    url: userApi.instructList,
    method: 'post',
    data: parameter
  })
}



